import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import api from '../../api';
import { IProject } from '../../api/services/project.service';
import { IFile } from '../../api/services/file.service';
import { ITag } from '../../api/services/tag.service';
import { ITrack } from '../../api/services/track.service';
import { useAuth } from '../../hooks/useAuth';
import { IStateProp } from './studioState';
import { IComposer } from '../../api/services/composer.service';

interface InternalStateProps {
  activeAlbum: IProject | null;
  setActiveAlbum: (id: IProject | null) => void;
  activeAlbumTracks: ITrack[];
  setActiveAlbumTracks: (albumTracks: ITrack[]) => void;
  activeAlbumTracksLoading: boolean;
  activeTrack: ITrack | null;
  setActiveTrack: (id: ITrack | null) => void;
  activeFiles: IFile[];
  setActiveFiles: (files: IFile[]) => void;
  activeActiveAlbumTags: ITag[];
  setActiveAlbumTags: (tags: ITag[]) => void;
  activeFilesLoading: boolean;
  refreshData: (state: IStateProp) => void;
  fetchAlbumTracks(id: string): Promise<void>;
  fetchAlbumFiles(id: string): Promise<void>;
  updateAlbum(album: Partial<IProject>): Promise<void>;
  isLoading: boolean;
  isUpdating: boolean;
  activeRole: string | null;
  setActiveRole: (id: string | null) => void;

  // composers related to track
  activeComposers: IComposer[];
  setActiveComposers: (composers: IComposer[]) => void;
  activeComposersLoading: boolean;
}

let AlbumStudioStateContext = React.createContext<InternalStateProps>(null!);

export function AlbumStudioStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { id } = useParams<{
    id: string;
  }>();

  const [activeComposers, setActiveComposers] = useState<IComposer[]>([]);
  const [activeComposersLoading, setActiveComposersLoading] = useState(false);

  const [activeAlbum, setActiveAlbum] = useState<IProject | null>(null);
  const [activeTrack, setActiveTrack] = useState<ITrack | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [activeFiles, setActiveFiles] = useState<IFile[]>([]);
  const [activeFilesLoading, setActiveFilesLoading] = useState<boolean>(false);
  const [activeAlbumTracks, setActiveAlbumTracks] = useState<ITrack[]>([]);
  const [activeActiveAlbumTags, setActiveAlbumTags] = useState<ITag[]>([]);
  const [activeAlbumTracksLoading, setActiveAlbumTracksLoading] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const [activeRole, setActiveRole] = useState<string | null>(null);

  const { activeTeam } = useAuth();

  useEffect(() => {
    if (id && activeTeam) {
      setIsLoading(true);
      Promise.all([
        fetchAlbum(id),
        fetchAlbumTracks(id),
        fetchAlbumFiles(id),
        fetchAlbumTags(id),
      ])
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, activeTeam]);

  function refreshData(property: IStateProp) {
    if (id) {
      switch (property) {
        case 'activeProject':
          fetchAlbum(id);
          console.log(activeAlbum);
          break;
        case 'activeFiles':
          fetchAlbumFiles(id);
          break;
        case 'activeProjectTracks':
          fetchAlbumTracks(id);
          break;
      }
    }
  }

  async function fetchAlbum(id: string) {
    try {
      const { data } = await api.album.getAlbum(id);
      setActiveAlbum(data.result);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchAlbumFiles(id: string) {
    try {
      setActiveFilesLoading(true);
      const { data } = await api.file.getFilesByProject(id);
      setActiveFiles(data.result);
      setActiveFilesLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchAlbumTracks(id: string) {
    try {
      setActiveAlbumTracksLoading(true);
      const { data } = await api.album.getTracksOfAlbum(id);
      setActiveAlbumTracks(data.result);
      setActiveAlbumTracksLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchAlbumTags(id: string) {
    try {
      setActiveAlbumTracksLoading(true);
      const { data } = await api.tag.getTagsByObject(id);
      setActiveAlbumTags(data.result);
      setActiveAlbumTracksLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function updateAlbum(album: Partial<IProject>) {
    if (!activeAlbum) return;
    try {
      setIsUpdating(true);
      const res = await api.album.updateAlbumPartially(activeAlbum.id, {
        ...album,
      });
      setActiveAlbum(res.data.result);
    } catch (e) {
      console.log(e);
    } finally {
      setIsUpdating(false);
    }
  }

  useEffect(() => {
    const role = searchParams.get('role');

    if (role) {
      setActiveRole(role);
    } else if (
      activeTeam?.teamMembershipDTO?.role === 'OWNER' ||
      activeTeam?.teamMembershipDTO?.role === 'MEMBER'
    ) {
      setActiveRole(activeTeam?.teamMembershipDTO?.role);
    } else {
      setActiveRole(null);
    }
  }, [searchParams, activeTeam]);

  return (
    <AlbumStudioStateContext.Provider
      value={{
        activeRole,
        setActiveRole,
        activeActiveAlbumTags,
        setActiveAlbumTags,
        activeAlbumTracksLoading,
        activeAlbum,
        setActiveAlbum,
        activeAlbumTracks,
        isLoading,
        setActiveAlbumTracks,
        activeTrack,
        setActiveTrack,
        activeFiles,
        activeFilesLoading,
        setActiveFiles,
        refreshData,
        fetchAlbumTracks,
        updateAlbum,
        fetchAlbumFiles,
        isUpdating,
        activeComposers,
        setActiveComposers,
        activeComposersLoading,
      }}
    >
      {children}
    </AlbumStudioStateContext.Provider>
  );
}

export function useAlbumStudioState() {
  return React.useContext(AlbumStudioStateContext);
}
