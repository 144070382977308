import { ITeam, ITeamMembership } from '../../api/services/team.service';
import { BiBuilding } from 'react-icons/bi';
import { isEmail } from '../../utils';
import StatusBadge from '../shared/StatusBadge';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { FaCheck, FaPen, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../api';
import { FormikErrors, useFormik } from 'formik';
import axios from 'axios';
import { Tooltip } from 'flowbite-react';
import { countryCodes } from './countryCodeOptions';
import { useNavigate } from 'react-router-dom';
import TeamArtworkUploader from './TeamArtworkUploader';

type TeamOverviewSectionProps = {
  members: ITeamMembership[];
  team: ITeam;
  reloadTeam: () => void;
};

interface FormValues {
  name: string;
  publisherName: string;
  ipi: string;
  pro: string;
  website: string;
  email: string;

  countryCode: string;
  labelCode: string;
  libraryCode?: string;
  isrc_min: number;
  isrc_max: number;
}

function TeamCard({
  team,
  reloadTeam,
}: {
  team: ITeam;
  reloadTeam: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!isTeamCompleted(team)) {
      setIsEditMode(true);
    }
  }, [team]);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: team?.name || '',
      publisherName: team?.publisherName || '',
      ipi: team?.ipi || '',
      pro: team?.pro || '',
      website: team?.website || '',
      email: team?.email || '',
      countryCode: team?.countryCode || '',
      labelCode: team?.labelCode || '',
      libraryCode: team?.libraryCode || '',
      isrc_min: team?.isrc_min || 0,
      isrc_max: team?.isrc_max || 0,
    },
    validateOnChange: true,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.publisherName) {
        errors.publisherName = 'is required';
      }
      if (!values.name) {
        errors.name = 'is required';
      }

      if (!values.ipi) {
        errors.ipi = 'is required';
      }
      if (!values.pro) {
        errors.pro = 'is required';
      }

      // IPI must be 9 characters
      if (values.ipi && (values.ipi.length < 9 || values.ipi.length > 11)) {
        errors.ipi = 'must be 9-11 characters';
      }

      if (!values.countryCode) {
        errors.countryCode = 'is required';
      }

      if (!countryCodes.has(values.countryCode.toUpperCase())) {
        errors.countryCode = 'must be a valid ISO code';
      }

      // registrant code must be 3 characters
      if (!values.labelCode) {
        errors.labelCode = 'is required';
      }

      if (!values.libraryCode) {
        errors.libraryCode = 'is required';
      }

      if (values.labelCode.length !== 3) {
        errors.labelCode = 'must be 3 characters';
      }

      if (values.email && !isEmail(values.email)) {
        errors.email = 'must be valid email';
      }

      if (values.isrc_max <= values.isrc_min) {
        errors.isrc_max = 'End of ISRC range must be greater than start';
      }

      return errors;
    },
    onSubmit: (values) => {
      // if (!team) return;
      console.log('submitting', values);
      setIsLoading(true);
      api.team
        .updateTeam(team.id, values)
        .then((res) => {
          toast.success('Team Information updated successfully');
          setIsLoading(false);
          setIsEditMode(false);
          reloadTeam();
        })
        .catch((e) => {
          setIsLoading(false);
          if (axios.isAxiosError(e)) {
            if (e.response?.data) {
              toast.error(e.response?.data.errorMessage);
            }
          } else {
            console.log(e);
            toast.error(
              'Something really went wrong, you might want to contact support!'
            );
          }
        });
    },
  });

  function mapInputKey(errorKey: string) {
    if (errorKey === 'labelCode') {
      return 'Registrant Code';
    }
    if (errorKey === 'libraryCode') {
      return 'Label Prefix';
    }
    if (errorKey === 'isrc_max') {
      return 'ISRC Range';
    }
    if (errorKey === 'countryCode') {
      return 'Country Code';
    }
    if (errorKey === 'publisherName') {
      return 'Publisher Name';
    }
    if (errorKey === 'name') {
      return 'Label Name';
    }
    if (errorKey === 'ipi') {
      return 'IPI';
    }
    if (errorKey === 'pro') {
      return 'PRO';
    }
    return errorKey;
  }

  const handleSubmit = () => {
    formik.validateForm().then((errors: FormikErrors<FormValues>) => {
      if (Object.keys(errors).length > 0) {
        toast.error(
          <div className='flex flex-col'>
            <div>The following issues occurred:</div>
            <ul className='ml-5 list-disc'>
              {Object.keys(errors).map((errorKey) => (
                <li key={errorKey}>
                  <b>{mapInputKey(errorKey)}</b>: {(errors as any)[errorKey]}
                </li>
              ))}
            </ul>
          </div>
        );
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <div className='overflow-hidden rounded-lg border border-slate-200 bg-white dark:border-slate-700 dark:bg-slate-900'>
      <div className='flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 p-6 dark:bg-slate-800'>
        <TeamArtworkUploader />
        <div className='text-sm font-medium leading-6 text-slate-900 dark:text-white'>
          {team.name}
        </div>
        {!isEditMode ? (
          // <MenuComponent
          //   team={team}
          //   editMode={isEditMode}
          //   setEditMode={setIsEditMode}
          // />
          <div className='flex flex-grow items-center justify-end'>
            <div
              onClick={() => setIsEditMode(true)}
              className='flex cursor-pointer items-center space-x-2 rounded bg-indigo-600 p-2 font-semibold text-white hover:bg-indigo-800 disabled:bg-slate-300 disabled:text-slate-200'
            >
              <FaPen />
            </div>
          </div>
        ) : (
          <div className='flex flex-grow items-center justify-end space-x-2'>
            <div
              onClick={handleSubmit}
              className='flex cursor-pointer items-center space-x-2 rounded bg-emerald-600 p-2 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200'
            >
              <FaCheck />
            </div>
            <div
              onClick={() => {
                setIsEditMode(false);
                formik.resetForm();
              }}
              className='flex cursor-pointer items-center space-x-2 rounded bg-red-600 p-2 font-semibold text-white hover:bg-red-800 disabled:bg-slate-300 disabled:text-slate-200'
            >
              <FaTimes />
            </div>
          </div>
        )}
      </div>
      <dl className='-my-3 divide-y divide-slate-200 px-6 py-4 text-sm leading-6 dark:divide-slate-400'>
        <SettingsLine
          error={!!formik.errors.name}
          hint={formik.errors.name || 'Your official label name'}
          onChange={formik.handleChange}
          value={formik.values.name}
          label='Label name'
          name='name'
          disabled={!isEditMode}
        />
        <SettingsLine
          error={!!formik.errors.publisherName}
          hint={formik.errors.publisherName || 'Your official publisher name'}
          onChange={formik.handleChange}
          value={formik.values.publisherName}
          label='Publisher name'
          name='publisherName'
          disabled={!isEditMode}
        />
        {team.teamMembershipDTO?.role !== 'GUEST' && (
          <>
            <SettingsLine
              error={!!formik.errors.ipi}
              hint={
                formik.errors.ipi ||
                'Interested Party Information (IPI) number is a unique, international identification number, usually 9-11 digits long'
              }
              onChange={formik.handleChange}
              value={formik.values.ipi}
              label='IPI'
              name='ipi'
              disabled={!isEditMode}
            />
            <SettingsLine
              error={!!formik.errors.pro}
              hint={
                formik.errors.pro ||
                'Performing Rights Organization (PRO) is the organization that collects royalties on behalf of songwriters and publishers'
              }
              onChange={formik.handleChange}
              value={formik.values.pro}
              label='PRO'
              name='pro'
              disabled={!isEditMode}
            />
            <SettingsLine
              error={!!formik.errors.countryCode}
              hint={
                formik.errors.countryCode ||
                'The 2 character ISO code of your country'
              }
              onChange={formik.handleChange}
              value={formik.values.countryCode}
              label='Country code'
              name='countryCode'
              disabled={!isEditMode}
            />
            <SettingsLine
              error={!!formik.errors.labelCode}
              hint={
                formik.errors.labelCode ||
                'The 3 character code you received from your national ISRC agency'
              }
              onChange={formik.handleChange}
              value={formik.values.labelCode}
              label='Registrant Code'
              name='labelCode'
              disabled={!isEditMode}
            />

            <div className='flex items-center justify-between gap-x-4 py-3'>
              <dt className={`w-1/3 text-slate-500 dark:text-white`}>
                <Tooltip
                  content={
                    formik.errors.isrc_max ||
                    'The ISRC range you want to allocate to this label'
                  }
                >
                  <span
                    className={`${
                      !!formik.errors.isrc_max ? 'text-red-500' : ''
                    }`}
                  >
                    ISRC Range
                  </span>
                </Tooltip>
              </dt>
              <dd className='flex w-2/3 items-center justify-between space-x-2 text-slate-700 dark:text-white'>
                <SettingsLine
                  error={!!formik.errors.isrc_min}
                  onChange={formik.handleChange}
                  value={formik.values.isrc_min}
                  type='number'
                  name='isrc_min'
                  disabled={!isEditMode}
                />
                <div>-</div>
                <SettingsLine
                  error={!!formik.errors.isrc_max}
                  onChange={formik.handleChange}
                  value={formik.values.isrc_max}
                  type='number'
                  name='isrc_max'
                  disabled={!isEditMode}
                />
              </dd>
            </div>

            <SettingsLine
              error={!!formik.errors.libraryCode}
              hint={
                formik.errors.libraryCode ||
                'A unique code for your library that will be used as a prefix for your Albums'
              }
              onChange={formik.handleChange}
              value={formik.values.libraryCode}
              label='Label prefix'
              name='libraryCode'
              disabled={!isEditMode}
            />
          </>
        )}
        <SettingsLine
          error={!!formik.errors.email}
          onChange={formik.handleChange}
          value={formik.values.email}
          label='Email'
          name='email'
          disabled={!isEditMode}
        />
        {isEditMode && (
          <SettingsLine
            error={!!formik.errors.website}
            onChange={formik.handleChange}
            value={formik.values.website}
            label='Website'
            name='website'
            disabled={!isEditMode}
          />
        )}
        {!isEditMode && (
          <div className='flex items-center justify-between gap-x-4 py-2'>
            <dt className='text-slate-500 dark:text-white'>Website</dt>
            <dd className='py-1.5 text-slate-700 dark:text-white'>
              <a
                rel='noreferrer'
                target='_blank'
                href={team.website}
                className='font-semibold text-indigo-600 hover:text-indigo-500'
              >
                {team.website}
              </a>
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
}

function SettingsLine({
  label,
  error,
  hint,
  ...rest
}: {
  label?: string;
  hint?: string;
  error?: boolean;
} & InputHTMLAttributes<HTMLInputElement>) {
  return label && label.length > 0 ? (
    <div className='flex items-center justify-between gap-x-4 py-2 '>
      <dt className='w-1/3 text-slate-500 dark:text-white'>
        {hint && (
          <Tooltip content={hint}>
            <label
              className={`flex items-center space-x-1 ${
                error ? 'text-red-500' : ''
              }`}
            >
              <div>{label}</div>
            </label>
          </Tooltip>
        )}
        {!hint && (
          <label
            className={`flex items-center space-x-1 ${
              error ? 'text-red-500' : ''
            }`}
          >
            <div>{label}</div>
          </label>
        )}
      </dt>
      <dd className='w-2/3 text-slate-700 dark:text-white'>
        <input
          id={label}
          name={label}
          type='text'
          className='block w-full rounded-md border-0 px-2 py-1.5 text-right text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:shadow-none disabled:ring-0 dark:bg-slate-800 dark:text-slate-200 sm:text-sm sm:leading-6'
          {...rest}
        />
      </dd>
    </div>
  ) : (
    <input
      id={label}
      name={label}
      type='text'
      className='block w-full rounded-md border-0 px-2 py-1.5 text-right  text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:shadow-none disabled:ring-0 dark:bg-slate-800 dark:text-slate-200 sm:text-sm sm:leading-6'
      {...rest}
    />
  );
}

function isTeamCompleted(team: ITeam): boolean {
  if (
    team.name &&
    team.publisherName &&
    team.ipi &&
    team.ipi.length >= 9 &&
    team.ipi.length <= 11 &&
    team.pro &&
    team.countryCode &&
    team.labelCode &&
    team.isrc_min !== undefined &&
    team.isrc_max &&
    team.libraryCode
    // team.website // team.email &&
  ) {
    return true;
  }
  return false;
}

export function TeamOverviewSection({
  members,
  team,
  reloadTeam,
}: TeamOverviewSectionProps) {
  const [isCompleted, setIsCompleted] = useState(false);
  const [initialCheck, setInitialCheck] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('TeamOverviewSection rendered');
    if (team) {
      const nowCompleted = isTeamCompleted(team);
      if (!initialCheck) {
        if (isCompleted === false && nowCompleted === true) {
          console.log('isCompleted changed from false to true');
          toast.success('Team information is now complete');
          navigate('/');
        }
      } else {
        setInitialCheck(false);
      }
      setIsCompleted(nowCompleted);
    }
  }, [team]);

  return (
    <div className='flex flex-col space-y-5'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-2'>
          <BiBuilding className='text-2xl text-indigo-500' />
          <div className='text-lg font-semibold'>Label Info</div>
        </div>
        <StatusBadge
          status={!isCompleted ? 'INCOMPLETE' : 'COMPLETE'}
          className={
            !isCompleted ? 'bg-amber-500 text-white' : 'bg-green-500 text-white'
          }
        />
      </div>
      <div className='flex w-full flex-col overflow-scroll'>
        <TeamCard team={team} reloadTeam={reloadTeam} />
      </div>
    </div>
  );
}
