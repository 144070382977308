import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { IFile } from '../../api/services/file.service';
import {
  IProject,
  IProjectInvitation,
  IProjectMembership,
} from '../../api/services/project.service';
import { ITrack } from '../../api/services/track.service';
import { useAuth } from '../../hooks/useAuth';
import { usePrevious } from '../../hooks/usePrevious';
import { Contract } from '../../api/services/contract.service';
import { FEATURES } from '../../config/features';
import { IComposer } from '../../api/services/composer.service';

export type IStateProp =
  | 'activeProject'
  | 'activeProjectMembers'
  | 'activeProjectInvites'
  | 'activeFiles'
  | 'activeProjectTracks'
  | 'activeTrack'
  | 'activeProjectContracts'
  | 'activeTrackComposers';

interface InternalStateProps {
  // active submission
  activeProjectId: string | null;
  setActiveProjectId: (id: string | null) => void;

  // active submission
  activeProject: IProject | null;
  setActiveProject: (id: IProject | null) => void;
  activeProjectLoading: boolean;

  // active submission members
  activeProjectMembers: IProjectMembership[];
  setActiveProjectMembers: (projectMembers: IProjectMembership[]) => void;
  activeProjectMembersLoading: boolean;

  // active personal submission role
  activeProjectRole: string | null;
  setActiveProjectRole: (id: string | null) => void;
  activeProjectRoleLoading: boolean;

  // active submission invites
  activeProjectInvites: IProjectInvitation[];
  setActiveProjectInvites: (projectInvites: IProjectInvitation[]) => void;
  activeProjectInvitesLoading: boolean;

  // active submission tracks
  activeProjectTracks: ITrack[];
  setActiveProjectTracks: (activeProjectTracks: ITrack[]) => void;
  activeProjectTracksLoading: boolean;

  // active track
  activeTrack: ITrack | null;
  setActiveTrack: (id: ITrack | null) => void;

  // files related to track
  activeFiles: IFile[];
  setActiveFiles: (files: IFile[]) => void;
  activeFilesLoading: boolean;

  // composers related to track
  activeComposers: IComposer[];
  setActiveComposers: (composers: IComposer[]) => void;
  activeComposersLoading: boolean;

  refreshData: (state: IStateProp) => void;
  updateTrackState: (trackId: string, track: ITrack) => void;

  trackUpdateLoading: boolean;
  setTrackUpdateLoading: (track: boolean) => void;

  canBeModified: boolean;

  isTrackValidationErrorsShown: boolean;
  setIsTrackValidationErrorsShown: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setTourActive: React.Dispatch<React.SetStateAction<boolean>>;
  isTourActive: boolean;
  activeContracts: Contract[];
  setActiveContracts: (contracts: Contract[]) => void;
}

let StudioStateContext = React.createContext<InternalStateProps>(null!);

export function StudioStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Submission Studio state
  const [isTourActive, setTourActive] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);
  const [activeProject, setActiveProject] = useState<IProject | null>(null);
  const activeProjectPrev = usePrevious(activeProject);
  const [activeProjectLoading, setActiveProjectLoading] =
    useState<boolean>(true);

  const [activeContracts, setActiveContracts] = useState<Contract[]>([]);
  const [activeProjectMembers, setActiveProjectMembers] = useState<
    IProjectMembership[]
  >([]);
  const [activeProjectMembersLoading, setActiveProjectMembersLoading] =
    useState<boolean>(false);

  const [activeProjectRole, setActiveProjectRole] = useState<string | null>(
    null
  );
  const [activeProjectRoleLoading, setActiveProjectRoleLoading] =
    useState<boolean>(false);

  const [activeProjectInvites, setActiveProjectInvites] = useState<
    IProjectInvitation[]
  >([]);
  const [activeProjectInvitesLoading, setActiveProjectInvitesLoading] =
    useState<boolean>(false);

  const [activeTrack, setActiveTrack] = useState<ITrack | null>(null);
  const [activeTrackLoading, setActiveTrackLoading] = useState(false);

  const [activeFiles, setActiveFiles] = useState<IFile[]>([]);
  const [activeFilesLoading, setActiveFilesLoading] = useState(false);

  const [activeComposers, setActiveComposers] = useState<IComposer[]>([]);
  const [activeComposersLoading, setActiveComposersLoading] = useState(false);

  const [activeProjectTracks, setActiveProjectTracks] = useState<ITrack[]>([]);
  const [activeProjectTracksLoading, setActiveProjectTracksLoading] =
    useState(false);
  const [trackUpdateLoading, setTrackUpdateLoading] = useState(false);
  const [isTrackValidationErrorsShown, setIsTrackValidationErrorsShown] =
    useState(false);

  // Generic state
  const { user, activeTeam, isFeatureActive } = useAuth();

  const [searchParams] = useSearchParams();

  // fetch all relevant project information once projectId is set
  useEffect(() => {
    // only fetch if user is logged in
    if (activeProjectId && activeTeam) {
      fetchProject(activeProjectId);
      fetchProjectMembers(activeProjectId);
      fetchProjectInvites(activeProjectId);
      fetchProjectFiles(activeProjectId);
      fetchProjectTracks(activeProjectId);
      if (isFeatureActive(FEATURES.KTR_CONTRACTING)) {
        fetchProjectContracts(activeProjectId);
      }
    }
  }, [activeProjectId, activeTeam]);

  // set user role when members are loaded
  useEffect(() => {
    const role = searchParams.get('role');
    if (role) {
      setActiveProjectRole(role);
    } else {
      if (activeProjectMembers.length > 0) {
        const userMember = activeProjectMembers.find(
          (u) => u.targetUserId === user?.id
        );
        if (userMember) {
          setActiveProjectRole(userMember.role);
        } else if (
          activeTeam?.teamMembershipDTO?.role === 'OWNER' ||
          activeTeam?.teamMembershipDTO?.role === 'MEMBER'
        ) {
          setActiveProjectRole(activeTeam?.teamMembershipDTO?.role);
        } else {
          setActiveProjectRole(null);
        }
      } else if (
        activeTeam?.teamMembershipDTO?.role === 'OWNER' ||
        activeTeam?.teamMembershipDTO?.role === 'MEMBER'
      ) {
        setActiveProjectRole(activeTeam?.teamMembershipDTO?.role);
      }
    }
  }, [activeProjectMembers, user, searchParams]);

  // update project with delay when values change
  // TODO: wow, improve this

  useEffect(() => {
    if (activeProject?.id === activeProjectPrev?.id) {
      if (
        activeProject &&
        activeProjectPrev &&
        (activeProject.name !== activeProjectPrev?.name ||
          (activeProjectPrev !== null &&
            activeProject.dueDate !== activeProjectPrev.dueDate))
      ) {
        const getData = setTimeout(async () => {
          updateProject(activeProject);
        }, 1000);
        return () => clearTimeout(getData);
      }
    }
  }, [activeProject]);

  function refreshData(property: IStateProp) {
    if (activeProjectId) {
      switch (property) {
        case 'activeProject':
          fetchProject(activeProjectId);
          break;
        case 'activeProjectMembers':
          fetchProjectMembers(activeProjectId);
          break;
        case 'activeProjectInvites':
          fetchProjectInvites(activeProjectId);
          break;
        case 'activeFiles':
          fetchProjectFiles(activeProjectId);
          break;
        case 'activeTrackComposers':
          fetchTrackComposers(activeTrack?.id || '');
          break;
        case 'activeProjectTracks':
          fetchProjectTracks(activeProjectId);
          break;
        case 'activeProjectContracts':
          fetchProjectContracts(activeProjectId);
          break;
        case 'activeTrack':
          if (activeTrack) {
            fetchTrack(activeTrack.id);
          }
          break;
      }
    }
  }

  function updateTrackState(trackId: string, track: ITrack) {
    const index = activeProjectTracks.findIndex((f) => f.id === trackId);
    setActiveProjectTracks([
      ...activeProjectTracks.slice(0, index),
      Object.assign(activeProjectTracks[index], track),
      ...activeProjectTracks.slice(index + 1, activeProjectTracks.length),
    ]);
    setActiveTrack(Object.assign(activeProjectTracks[index], track));
    console.log(Object.assign(activeProjectTracks[index], track));
  }

  // ---------- fetcher

  async function fetchProject(id: string) {
    try {
      setActiveProjectLoading(true);
      const { data } = await api.project.getProject(id);
      setActiveProject(data.result);
      setActiveProjectLoading(false);
    } catch (e) {
      console.log(e);
      setActiveProjectLoading(false);
      setActiveProject(null);
    }
  }

  async function fetchProjectFiles(id: string) {
    try {
      setActiveFilesLoading(true);
      const { data } = await api.file.getFilesByProject(id);
      setActiveFiles(data.result);
      setActiveFilesLoading(false);
    } catch (e) {
      console.log(e);
      setActiveFiles([]);
    }
  }

  async function fetchTrackComposers(trackId: string) {
    try {
      setActiveComposersLoading(true);
      const { data } = await api.composer.getComposersByTrack(trackId);
      setActiveComposers(data.result);
      setActiveComposersLoading(false);
    } catch (e) {
      console.log(e);
      setActiveComposers([]);
    }
  }

  async function fetchProjectTracks(id: string) {
    try {
      setActiveProjectTracksLoading(true);
      const { data } = await api.track.getTracksByProject(id);
      setActiveProjectTracks(data.result);
      setActiveProjectTracksLoading(false);
    } catch (e) {
      console.log(e);
      setActiveProjectTracks([]);
    }
  }
  async function fetchProjectContracts(id: string) {
    try {
      setActiveProjectTracksLoading(true);
      const { data } = await api.contract.getProjectContracts(id);
      setActiveContracts(data.result);
    } catch (e) {
      console.log(e);
      setActiveContracts([]);
    }
  }

  async function fetchTrack(id: string) {
    try {
      setActiveProjectTracksLoading(true);
      const { data } = await api.track.getTrack(id);
      const index = activeProjectTracks.findIndex((f) => f.id === id);
      setActiveProjectTracks([
        ...activeProjectTracks.slice(0, index),
        data.result,
        ...activeProjectTracks.slice(index + 1, activeProjectTracks.length),
      ]);
      setActiveTrack(data.result);
      setActiveProjectTracksLoading(false);
    } catch (e) {
      console.log(e);
      setActiveTrack(null);
    }
  }

  async function fetchProjectMembers(id: string) {
    try {
      setActiveProjectMembersLoading(true);
      const { data } = await api.project.getProjectMembers(id);
      setActiveProjectMembers(data.result);
      setActiveProjectMembersLoading(false);
    } catch (e) {
      console.log(e);
      setActiveProjectMembers([]);
    }
  }

  async function fetchProjectInvites(projectId: string) {
    try {
      if (activeProjectRole && activeProjectRole === 'GUEST') {
        return;
      }
      setActiveProjectInvitesLoading(true);
      const { data } = await api.project.getProjectInvites(projectId);
      setActiveProjectInvites(data.result);
      setActiveProjectInvitesLoading(false);
    } catch (e) {
      console.log(e);
      setActiveProjectInvites([]);
    }
  }

  // ---------- updater

  async function updateProject(project: IProject) {
    const { id, name, dueDate } = project;
    try {
      const { status } = await api.project.updateProjectPartially(id, {
        name,
        dueDate,
      });
      if (status === 200) {
        toast.success('Project updated');
      }
    } catch (e) {
      console.log(e);
    }
  }

  function setActiveTrackWrapper(activeTrack: ITrack | null) {
    // delay track switch while update is in progress, prevents glitch where user stays on tab thats updated
    if (!trackUpdateLoading) {
      setActiveTrack(activeTrack);
    } else {
      setTimeout(() => {
        setActiveTrack(activeTrack);
      }, 200);
    }
  }

  const [canBeModified, setCanBeModified] = useState<boolean>(false);

  useEffect(() => {
    if (activeProjectRole === 'OWNER') {
      setCanBeModified(true);
    } else if (
      activeProjectRole === 'GUEST' &&
      activeProject?.status === 'REQUESTED'
    ) {
      setCanBeModified(true);
    } else {
      setCanBeModified(false);
    }
  }, [activeProjectRole, activeProject]);

  let value = {
    // submission state
    activeProjectId,
    setActiveProjectId,
    activeProject,
    activeProjectLoading,
    setActiveProject,
    activeProjectMembers,
    activeProjectMembersLoading,
    setActiveProjectMembers,
    activeProjectRole,
    activeProjectRoleLoading,
    setActiveProjectRole,
    activeProjectInvites,
    activeProjectInvitesLoading,
    setActiveProjectInvites,
    activeProjectTracks,
    activeProjectTracksLoading,
    setActiveProjectTracks,

    // track and file state
    activeTrack,
    activeTrackLoading,
    setActiveTrack: setActiveTrackWrapper,
    activeFiles,
    activeFilesLoading,
    setActiveFiles,
    // refresh Data
    setActiveComposers,
    activeComposers,
    activeComposersLoading,
    refreshData,
    updateTrackState,
    setTrackUpdateLoading,
    trackUpdateLoading,
    canBeModified,
    isTrackValidationErrorsShown,
    setIsTrackValidationErrorsShown,
    setTourActive,
    isTourActive,
    activeContracts,
    setActiveContracts,
  };

  return (
    <StudioStateContext.Provider value={value}>
      {children}
    </StudioStateContext.Provider>
  );
}

export function useStudioState() {
  return React.useContext(StudioStateContext);
}
